<template>
  <section id="faq" class="page-container">
    <h2
      class="mt-24 w-24 h-11 py-2 px-4 mx-auto bg-indigo-500 rounded-full text-white flex items-center justify-center font-bold"
      v-motion-slide-visible-once-left
    >
      FAQ
    </h2>
    <h3
      class="font-bold text-3xl lg:text-5xl text-center my-10"
      v-motion-slide-visible-once-right
    >
      {{ $t("landingPage.faq.title") }}
    </h3>
    <p
      class="mt-8 text-xl text-center text-gray-500 lg:text-2xl lg:mx-auto lg:w-3/4"
      v-motion-slide-visible-once-left
    >
      {{ $t("landingPage.faq.description") }}
    </p>
    <div class="mt-10 flex flex-col gap-y-10 list-none md:mx-10">
      <DefaultButton
        v-for="(item, i) in faqItems"
        :key="i"
        class="pt-8 px-4 md:px-8 h-auto rounded-3xl transition-all duration-200 ease-in-out flex-col items-start"
        :class="
          currentActiveIndex === i ? 'h-auto bg-[#5363FE]' : 'bg-zinc-900'
        "
        color="transparent"
        :onClick="() => openFaq(i)"
        v-motion-slide-visible-once-bottom
      >
        <div class="flex justify-between items-center mb-6 w-full">
          <div class="flex items-center">
            <svg
              class="text-3xl me-4 h-3 md:h-6"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0621 1.53451C10.3843 0.663889 11.6157 0.663888 11.9379 1.53451L14.0815 7.32767C14.1828 7.60139 14.3986 7.8172 14.6723 7.91848L20.4655 10.0621C21.3361 10.3843 21.3361 11.6157 20.4655 11.9379L14.6723 14.0815C14.3986 14.1828 14.1828 14.3986 14.0815 14.6723L11.9379 20.4655C11.6157 21.3361 10.3843 21.3361 10.0621 20.4655L7.91848 14.6723C7.8172 14.3986 7.60139 14.1828 7.32767 14.0815L1.53451 11.9379C0.663889 11.6157 0.663888 10.3843 1.53451 10.0621L7.32767 7.91848C7.60139 7.8172 7.8172 7.60139 7.91848 7.32767L10.0621 1.53451Z"
                fill="white"
              />
            </svg>
            <p class="lg:text-3xl w-52 md:w-full font-bold text-left">
              {{ $t(item.title) }}
            </p>
          </div>

          <span class="material-symbols-outlined">
            {{ currentActiveIndex === i ? "remove" : "add" }}
          </span>
        </div>

        <Translation
          v-if="currentActiveIndex === i"
          tag="p"
          class="mb-6 text-base lg:text-xl font-normal text-left"
          :keypath="item.answer"
        >
          <br />
          <template
            v-if="item.links?.length"
            v-for="link in item.links"
            :key="link.label"
          >
            <NuxtLink
              v-if="link.to.startsWith('/')"
              :to="link.to"
              class="font-bold hover:underline"
            >
              {{ $t(link.label) }}
            </NuxtLink>
            <a
              v-else
              :href="link.to"
              :target="link.target ?? '_self'"
              class="font-bold hover:underline"
            >
              {{ $t(link.label) }}
            </a>
          </template>
        </Translation>
      </DefaultButton>
    </div>
  </section>
</template>

<script lang="ts" setup>
const currentActiveIndex = ref<number | false>(false);

const faqItems = [
  {
    title: "landingPage.faq.questions.doubt1",
    answer: "landingPage.faq.questions.answer1",
    links: [
      {
        label: "landingPage.faq.questions.labels.here",
        to: "/login",
      },
      {
        label: "landingPage.faq.questions.labels.plans",
        to: "#pricing",
      },
    ],
  },
  {
    title: "landingPage.faq.questions.doubt2",
    answer: "landingPage.faq.questions.answer2",
    links: [
      {
        label: "landingPage.faq.questions.labels.look",
        to: "#pricing",
      },
    ],
  },
  {
    title: "landingPage.faq.questions.doubt3",
    answer: "landingPage.faq.questions.answer3",
  },
  {
    title: "landingPage.faq.questions.doubt4",
    answer: "landingPage.faq.questions.answer4",
    links: [
      {
        label: "landingPage.faq.questions.labels.supportChannel",
        to: "mailto:contact@realitystudio.ai",
      },
      {
        label: "landingPage.faq.questions.labels.supportTeam",
        to: "mailto:contact@realitystudio.ai",
      },
    ],
  },
  {
    title: "landingPage.faq.questions.doubt5",
    answer: "landingPage.faq.questions.answer5",
    links: [
      {
        label: "Stripe",
        to: "https://stripe.com/",
        target: "_blank",
      },
    ],
  },
  {
    title: "landingPage.faq.questions.doubt6",
    answer: "landingPage.faq.questions.answer6",
    links: [
      {
        label: "blog",
        to: "https://blog.realitystudio.ai",
        target: "_blank",
      },
    ],
  },
  {
    title: "landingPage.faq.questions.doubt7",
    answer: "landingPage.faq.questions.answer7",
  },
  {
    title: "landingPage.faq.questions.doubt8",
    answer: "landingPage.faq.questions.answer8",
    links: [
      {
        label: "landingPage.faq.questions.labels.supportTeam",
        to: "mailto:contact@realitystudio.ai",
      },
      {
        label: "landingPage.faq.questions.labels.here",
        to: "/my-account/settings/delete",
      },
    ],
  },
  {
    title: "landingPage.faq.questions.doubt9",
    answer: "landingPage.faq.questions.answer9",
  },
  {
    title: "landingPage.faq.questions.doubt10",
    answer: "landingPage.faq.questions.answer10",
    links: [
      {
        label: "landingPage.faq.questions.labels.tools",
        to: "#solutions",
      },
      {
        label: "landingPage.faq.questions.labels.filters",
        to: "#solutions",
      },
    ],
  },
  {
    title: "landingPage.faq.questions.doubt11",
    answer: "landingPage.faq.questions.answer11",
    links: [
      {
        label: "landingPage.faq.questions.labels.supportTeam",
        to: "mailto:contact@realitystudio.ai",
      },
    ],
  },
  {
    title: "landingPage.faq.questions.doubt12",
    answer: "landingPage.faq.questions.answer12",
    links: [
      {
        label: "blog",
        to: "https://blog.realitystudio.ai",
        target: "_blank",
      },
      {
        label: "contact@realitystudio.ai",
        to: "mailto:contact@realitystudio.ai",
      },
    ],
  },
];

const openFaq = (index: number) => {
  if (currentActiveIndex.value === index) {
    currentActiveIndex.value = false;
    return;
  }
  currentActiveIndex.value = index;
};
</script>
